import React from 'react';
import { DashboardNotificationsContainer } from './styles';
import BankAccountError from 'components/Dashboard/BankAccountError';
import { getDataStorage } from 'utils/storage';
import BlockedTransactionsBanner from 'components/Dashboard/BlockedTransactionsBanner';
import PendingDocumentsBanner from 'components/Dashboard/PendingDocumentsBanner';
import DigitalAccountInviteDialog from 'components/Dashboard/DigitalAccountInviteDialog';

export default function DashboardNotifications() {
  const isMainUser = getDataStorage("main_user")
  return <>
    {
      isMainUser &&
      <>
        <DashboardNotificationsContainer>
          <BlockedTransactionsBanner />
          <PendingDocumentsBanner />
          <BankAccountError />
        </DashboardNotificationsContainer>
        {
          false && // TO-DO: Remover `false &&` quando for permitida a exibição do dialog de criação da Conta Digital Pagcerto
          <DigitalAccountInviteDialog />
        }
      </>
    }
  </>;
}
