import { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { checkUserAllowence } from 'utils/checkUserAllowence';
import { fetchGetAccountMyAccount } from 'services/MyAccount/GET/GetAccountMyAccountService';
import { fetchGetSigninUser } from 'services/Signin/GET/GetSigninUserService';
import { fetchGetAccount } from 'services/Account/GET/GetAccountService';
import { fetchGetDigitalAccountProducts } from 'services/Products/GET/GetDigitalAccountProductsService';
const DashboardContext = createContext();

export default function DashboardProvider({ children }) {
  const mounted = useRef(false);
  const [currentTab, setCurrentTab] = useState("overview")
  const [currentSettingsMenuItem, setCurrentSettingsMenuItem] = useState("menu")
  const [disabledEdition, setDisabledEdition] = useState(false)
  const [userName, setUserName] = useState("")
  const [showAccountVerificationMenu, setShowAccountVerificationMenu] = useState(false)
  const [showAccountDataMenu, setShowAccountDataMenu] = useState(false)
  const [hasDigitalAccount, setHasDigitalAccount] = useState(false)
  const [isLoading, setLoading] = useState(true)
  const [isDigitalAccountInviteDialogOpen, setDigitalAccountInviteDialogOpen] = useState(false)
  const [showBlockedTransactionsBanner, setShowBlockedTransactionsBanner] = useState(false)
  const [showPendingDocumentsBanner, setShowPendingDocumentsBanner] = useState(false)
  const [verificationStatusTag, setVerificationStatusTag] = useState("")

  const goBackToSettingsMenu = () => {
    setCurrentSettingsMenuItem("menu")
  }

  const getDashboardData = useCallback(async () => {
    const allowence = await checkUserAllowence()
    const { name } = await fetchGetSigninUser()
    const { verification_status, use_digital_account, account_register, last_sale_date, id } = await fetchGetAccountMyAccount()
    const { has_sale } = await fetchGetAccount()
    const digitalAccountProduct = await fetchGetDigitalAccountProducts({
      accountId: id,
    })
    if (mounted.current) {
      setDisabledEdition(!allowence)
      setUserName(name.split(" ")[0])
      setShowAccountVerificationMenu(verification_status?.tag !== "verified" || verification_status?.level !== 5)
      setShowAccountDataMenu(account_register?.status?.tag !== "active")
      setHasDigitalAccount(use_digital_account)
      setDigitalAccountInviteDialogOpen(!!last_sale_date && !digitalAccountProduct?.status?.tag)
      setShowBlockedTransactionsBanner(verification_status?.tag !== "verified" && has_sale)
      setShowPendingDocumentsBanner(verification_status?.tag !== "verified" && !has_sale)
      setVerificationStatusTag(verification_status?.tag)
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [currentTab, currentSettingsMenuItem])

  useEffect(() => {
    getDashboardData()
  }, [getDashboardData])

  useEffect(() => {
    mounted.current = true
    return () => { mounted.current = false }
  }, [])

  return <>
    <DashboardContext.Provider value={{
      currentTab,
      setCurrentTab,
      currentSettingsMenuItem,
      setCurrentSettingsMenuItem,
      goBackToSettingsMenu,
      disabledEdition,
      setDisabledEdition,
      userName,
      setUserName,
      showAccountVerificationMenu,
      showAccountDataMenu,
      hasDigitalAccount,
      isDigitalAccountInviteDialogOpen,
      setDigitalAccountInviteDialogOpen,
      showBlockedTransactionsBanner,
      showPendingDocumentsBanner,
      verificationStatusTag,
      isLoading,
    }}>
      {children}
    </DashboardContext.Provider>
  </>;
}

export function useDashboard() {
  const {
    currentTab,
    setCurrentTab,
    currentSettingsMenuItem,
    setCurrentSettingsMenuItem,
    goBackToSettingsMenu,
    disabledEdition,
    setDisabledEdition,
    userName,
    setUserName,
    showAccountVerificationMenu,
    showAccountDataMenu,
    hasDigitalAccount,
    isDigitalAccountInviteDialogOpen,
    setDigitalAccountInviteDialogOpen,
    showBlockedTransactionsBanner,
    showPendingDocumentsBanner,
    verificationStatusTag,
    isLoading,
  } = useContext(DashboardContext)
  return {
    currentTab,
    setCurrentTab,
    currentSettingsMenuItem,
    setCurrentSettingsMenuItem,
    goBackToSettingsMenu,
    disabledEdition,
    setDisabledEdition,
    userName,
    setUserName,
    showAccountVerificationMenu,
    showAccountDataMenu,
    hasDigitalAccount,
    isDigitalAccountInviteDialogOpen,
    setDigitalAccountInviteDialogOpen,
    showBlockedTransactionsBanner,
    showPendingDocumentsBanner,
    verificationStatusTag,
    isLoading,
  }
}
