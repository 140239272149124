import apiPrivate from 'services/apiPrivate'
import { getDataStorage } from 'utils/storage'

export const fetchGetDigitalAccountProducts = async ({ accountId }) => {
  const yapay_code = getDataStorage('yapay_code')
  return apiPrivate.get("/products", {
    headers: {
      code: yapay_code,
    },
    params: {
      account_id: accountId,
      product: 'digital_account',
      kind: 'pagcerto',
    }
  })
    .then(({ data }) => {
      return data?.data[0]
    })
    .catch((error) => {
      console.error(error)
      return error
    })
}